import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Xampinyó blanc amb capell de quasi 15 cm de diàmetre que passa de convex a quasi pla amb el centre un poc deprimit i el marge gruixut i massís. Cutícula inicialment llisa i després coberta d’esquames. Davall el capell es troben nombroses làmines lliures, estretes, de color rosa salmó a gris carn. El peu de color blanc és irregular, cilíndric, gruixut, ple i rabassut. Aquest acaba típicament en punta i és llis per damunt l’anell, que és ínfer, i per davall pot presentar esquames de color terrós. Les espores són de color marró fosc en massa, de 5,5-8 x 4,5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      